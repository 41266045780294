import ReactPlayer from "react-player";
import { state } from "../../store";
import { useSnapshot } from "valtio";

export default function Video() {
  const snap = useSnapshot(state);
  return !snap.showPresentation ? null : (
    <div className="video-page">
      <ReactPlayer
        style={{ objectFit: "cover" }}
        url={"/model/presentation.mp4"}
        muted={false}
        playing={true}
        width={"130%"}
        height={"130%"}
        onEnded={() => (state.showPresentation = false)}
      />
      <h2 className="skipBtn" onClick={() => (state.showPresentation = false)}>
        Skip Video
      </h2>
    </div>
  );
}
