/* eslint-disable react-hooks/exhaustive-deps */
import { Canvas } from "@react-three/fiber";
import {
  Environment,
  Lightformer,
  OrbitControls,
  PerspectiveCamera,
  Preload,
  MeshReflectorMaterial,
  Text,
} from "@react-three/drei";
import { Brabus } from "./componentForThree/Brabus";
import { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import Loading from "./component/loading";
import { Suspense } from "react";
import { useSnapshot } from "valtio";
import { state } from "./store";
import { Effects } from "./componentForThree/Effects";
import { CarInformation } from "./component/CarInformation";
import { ButtonGroup } from "./component/ButtonGroup";
import { Title } from "./component/Title";
import { DetailButton } from "./componentForThree/DetailButton";
import { car_details } from "./assets/car_details";
import Video from "./component/video";
import Ads from "./component/ads";

export default function App() {
  const cameraRef = useRef();
  const [isAnimation, setIsAnimation] = useState(false);
  const orbitControls = useRef();
  const timelineRef = useRef();
  useEffect(() => {
    timelineRef.current = gsap.timeline({paused: true});
  }, [])
  const carAnimation = () => {
    if(!isAnimation) {
      setIsAnimation(true);
      timelineRef.current.to(cameraRef.current.position, {
        onStart: () => {
          orbitControls.current.autoRotate = false;
          orbitControls.current.minDistance = 0;
        },
        duration: 3,
        overwrite: true,
        x: -6.08,
        y: 0.043,
        z: 3.6,
        ease: "power1.inOut",
      })
        .to(cameraRef.current.position, {
          duration: 3,
          x: -3.87,
          y: 0.028,
          z: 2.365,
          ease: "power1.inOut",
        })
        .to(cameraRef.current.position, {
          duration: 3,
          x: -1.9,
          y: 0.028,
          z: -3.388,
          ease: "power1.inOut",
          onComplete: () => {
            cameraRef.current.position.set(-1.175, -0.377, 2.975);
            orbitControls.current.maxPolarAngle = Math.PI / 1.5;
            orbitControls.current.target.set(0, -1, 0);
          },
        })
        .to(cameraRef.current.position, {
          duration: 5,
          x: -1.15,
          y: -0.4,
          z: 2.8,
          ease: "power1.inOut",
          onComplete: () => {
            cameraRef.current.position.set(-7, 0.043, 0.95);
            orbitControls.current.target.set(0, -0.5, 0);
          },
        })
        .to(cameraRef.current.position, {
          duration: 3,
          x: -3.25,
          y: -0.115,
          z: 1.95,
          ease: "power1.inOut",
        })
        .to(cameraRef.current.position, {
          duration: 2,
          x: -3.25,
          y: -0.097,
          z: 2.327,
          ease: "power1.inOut",
        })
  
        .to(cameraRef.current.position, {
          duration: 2,
          x: -3.87,
          y: 0.028,
          z: 2.365,
          ease: "power1.inOut",
        })
        .to(cameraRef.current.position, {
          duration: 2,
          x: -0.76,
          y: 3.042,
          z: 0.445,
          ease: "power1.inOut",
        })
        .to(cameraRef.current.position, {
          duration: 3,
          x: -1.4,
          y: 1.94,
          z: 6.65,
          ease: "power1.inOut",
          onComplete: () => {
            orbitControls.current.target.set(0, 0, 0);
            orbitControls.current.maxPolarAngle = Math.PI / 2;
            setIsAnimation(false);
            orbitControls.current.minDistance = 4;
          },
        });
        timelineRef.current.play();
    } else {
      setIsAnimation(false);
      timelineRef.current.pause();
      orbitControls.current.target.set(0, 0, 0);
      orbitControls.current.maxPolarAngle = Math.PI / 2;
      orbitControls.current.minDistance = 4;
    };

  };

  const lightRef = useRef(null);

  const snap = useSnapshot(state);

  useEffect(() => {
    if (
      snap.displayInformation.state &&
      snap.displayInformation.id === "engine"
    ) {
      gsap.to(cameraRef.current.position, {
        onStart: () => {
          state.intro = false;
        },
        duration: 3,
        x: 0,
        y: 0,
        z: -5,
        ease: "power1.inOut",
      });
    } else if (
      snap.displayInformation.state &&
      snap.displayInformation.id === "wheel"
    )
      gsap.to(cameraRef.current.position, {
        onStart: () => {
          state.intro = false;
          // orbitControls.current.target.set(0, -0.5, 1.5);
        },
        duration: 3,
        x: 2,
        y: -2.5,
        z: 2,
        ease: "power1.inOut",
      });
    else if (
      snap.displayInformation.state &&
      snap.displayInformation.id === "exterior"
    )
      gsap.to(cameraRef.current.position, {
        onStart: () => {
          state.intro = false;
        },
        duration: 3,
        x: 5,
        y: 2,
        z: -4,
        ease: "power1.inOut",
      });
    else if (
      snap.displayInformation.state &&
      snap.displayInformation.id === "interior"
    )
      gsap.to(cameraRef.current.position, {
        duration: 3,
        x: -3,
        y: 0.7,
        z: 0.8,
        ease: "power1.inOut",
        onComplete: () => {
          state.intro = true;
        },
      });
  }, [snap.displayInformation]);

  return (
    <>
      <Title />
      <CarInformation />
      <ButtonGroup isAnimation={isAnimation} carAnimation={carAnimation} />

      <Canvas frameloop={snap.showVideo || snap.showPresentation ? "demand" : "always"}>
        <Suspense fallback={null}>
          <Brabus color={snap.color} open={snap.intro} />
          <ambientLight ref={lightRef} intensity={-0.3} />
          <Ground />
          {Object.keys(car_details).map((key, index) => (
            <DetailButton
              key={index}
              position={car_details[key].position}
              lines={car_details[key].lines}
              buttonName={car_details[key].buttonName}
              id={key}
            />
          ))}
          <LogoText />
          <PerspectiveCamera
            ref={cameraRef}
            makeDefault
            position={[-10, 5, -30]}
          />
          <OrbitControls
            ref={orbitControls}
            camera={cameraRef.current}
            enablePan={false}
            enableDamping={false}
            maxPolarAngle={Math.PI / 2}
            maxDistance={10}
            minDistance={4}
            makeDefault
          />
          <color attach="background" args={["#222121"]} />
          <Environment
            files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/studio_small_09_1k.hdr"
            resolution={0}
          />
          <Effects />
        </Suspense>
        <Preload all />
      </Canvas>
      <Loading />
      <Video />
      <Ads />
    </>
  );
}

function Ground() {
  return (
    <mesh position={[0, -1.16, 0]} rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry args={[10, 10]} />
      <MeshReflectorMaterial
        blur={[0, 0]}
        resolution={1024}
        mixBlur={1}
        mixStrength={40}
        roughness={1}
        depthScale={1}
        minDepthThreshold={0.4}
        maxDepthThreshold={1.4}
        color="#222121"
        metalness={1}
      />
    </mesh>
  );
}

const LogoText = () => {
  return (
    <group
      rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
      position={[-1.5, -1.15, 0]}
    >
      <Text color="white" anchorX="center" anchorY="middle" scale={0.15}>
        BLACK IST THE NEW BLACK.
      </Text>
      <Text
        color="red"
        anchorX="center"
        anchorY="middle"
        position={[0, -0.2, 0]}
        scale={0.2}
      >
        BRABUS 800 SUPERBLACK.
      </Text>
    </group>
  );
};
