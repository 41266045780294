export const Title = () => {
  return (
    <div className="title">
      <img
        alt="3D brabus configurator"
        src="/brabus-vector-logo/brabus_white.svg"
        className="title-logo"
      />
    </div>
  );
};
