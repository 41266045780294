import { useCountUp } from "react-countup";
import { useRef, useState } from "react";
import { state } from "../../store";
import { useSnapshot } from "valtio";
import { car_details } from "../../assets/car_details";

const Counter = ({ startValue = 0, end, decimals = 0, children }) => {
  const [endValue, setEndValue] = useState(end);
  const countUpRef = useRef();
  const { start, update } = useCountUp({
    ref: countUpRef,
    start: startValue,
    end: endValue,
    decimal: ".",
    decimals,
    duration: 2,
  });
  const updateFunc = (val) => {
    setEndValue(val);
    update(val);
  };
  return (
    <div className="car-information-children">
      {children(countUpRef, start, updateFunc, endValue)}
    </div>
  );
};

export const CarInformation = () => {
  const snap = useSnapshot(state);

  const [firstTitle, setFirstTitle] = useState("0-100");
  return !snap.displayInformation.state || !snap.displayInfoButton ? (
    <div className="car-information">
      <Counter
        end={4.1}
        decimals={1}
        children={(countUpRef, start) => {
          return (
            <>
              <p className="text-normal" onClick={start}>
                {firstTitle}
              </p>
              <p className="text-number" ref={countUpRef} />
              <div className="unit-area">
                <p
                  className="text-normal"
                  onClick={() => {
                    if (firstTitle !== "0-100") setFirstTitle("0-100");
                    start();
                  }}
                  style={{ color: firstTitle !== "0-100" && "#555555" }}
                >
                  KM/H
                </p>
                <p
                  className="text-normal"
                  onClick={() => {
                    if (firstTitle !== "0-60") setFirstTitle("0-60");
                    start();
                  }}
                  style={{ color: firstTitle !== "0-60" && "#555555" }}
                >
                  MPH
                </p>
              </div>
            </>
          );
        }}
      />
      <Counter
        end={1000}
        duration={0.1}
        children={(countUpRef, start) => {
          return (
            <>
              <p className="text-normal" onClick={start}>
                TORQUE
              </p>
              <p className="text-number" ref={countUpRef} />
              <p className="text-normal" onClick={start}>
                NM
              </p>
            </>
          );
        }}
      />
      <Counter
        end={588}
        duration={0.1}
        children={(countUpRef, start, updateFunc, end) => {
          return (
            <>
              <p
                className="text-normal"
                onClick={() => {
                  start();
                }}
              >
                POWER
              </p>
              <p className="text-number" ref={countUpRef} />
              <div className="unit-area">
                <p
                  className="text-normal"
                  onClick={() => {
                    if (end !== 588) updateFunc(588);
                    else start();
                  }}
                  style={{ color: end !== 588 && "#555555" }}
                >
                  KW
                </p>
                <p
                  className="text-normal"
                  onClick={() => {
                    if (end !== 800) updateFunc(800);
                    else start();
                  }}
                  style={{ color: end !== 800 && "#555555" }}
                >
                  HP
                </p>
              </div>
            </>
          );
        }}
      />
      <Counter
        end={240}
        duration={0.1}
        children={(countUpRef, start, updateFunc, end) => {
          return (
            <>
              <p
                className="text-normal"
                onClick={() => {
                  start();
                }}
              >
                VMAX
              </p>
              <p className="text-number" ref={countUpRef} />
              <div className="unit-area">
                <p
                  className="text-normal"
                  onClick={() => {
                    if (end !== 240) updateFunc(240);
                    else start();
                  }}
                  style={{ color: end !== 240 && "#555555" }}
                >
                  KM/H
                </p>
                <p
                  className="text-normal"
                  onClick={() => {
                    if (end !== 149) updateFunc(149);
                    else start();
                  }}
                  style={{ color: end !== 149 && "#555555" }}
                >
                  MPH
                </p>
              </div>
            </>
          );
        }}
      />
    </div>
  ) : (
    <div className="car-information" style={{ flexDirection: "column" }}>
      <p className="text-normal" style={{ color: "#FF0000" }}>
        {car_details[snap.displayInformation.id].title}
      </p>
      <p className="text-normal">
        {car_details[snap.displayInformation.id].content}
      </p>
    </div>
  );
};
