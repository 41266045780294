import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { BiSolidDoorOpen } from "react-icons/bi";
import { AiOutlineFundView } from "react-icons/ai";
import { LiaDoorClosedSolid } from "react-icons/lia";
import { FaPause, FaPlay } from 'react-icons/fa6';
import { state } from "../../store";
import { useSnapshot } from "valtio";
import { MdAirplay } from "react-icons/md";

export const ButtonGroup = (props) => {
  const { isAnimation, carAnimation } = props || {};
  const snap = useSnapshot(state);
  return (
    <div className="btn-group">
      <div
        className="btn tooltip"
        style={{
          pointerEvents: isAnimation && "none",
        }}
        onClick={() => {
          state.displayInfoButton = !snap.displayInfoButton;
        }}
      >
        {!snap.displayInfoButton ? (
          <IoMdEye
            className="btn-icon"
            style={{
              color: isAnimation && "#494949",
            }}
          />
        ) : (
          <IoMdEyeOff
            className="btn-icon"
            style={{
              color: isAnimation && "#494949",
            }}
          />
        )}
        <span className="tooltiptext">display information</span>
      </div>
      <div
        className="btn tooltip"
        style={{
          pointerEvents: isAnimation && "none",
        }}
        onClick={() => {
          state.intro = !state.intro;
        }}
      >
        {state.intro ? (
          <LiaDoorClosedSolid
            className="btn-icon"
            style={{
              color: isAnimation && "#494949",
            }}
          />
        ) : (
          <BiSolidDoorOpen
            className="btn-icon"
            style={{
              color: isAnimation && "#494949",
            }}
          />
        )}
        <span className="tooltiptext">open the door</span>
      </div>
      <div
        className="btn tooltip"
        style={{
          pointerEvents:
            (state.intro || snap.displayInfoButton) && "none",
        }}
        onClick={() => carAnimation()}
      >
        {
          !isAnimation ? (
            <FaPlay
            className="btn-icon"
            style={{
              color:
                (state.intro || snap.displayInfoButton) &&
                "#494949",
            }}
          />
          ) : (
            <FaPause
            className="btn-icon"
            style={{
              color:
                (state.intro || snap.displayInfoButton) &&
                "#494949",
            }}
          />
          )
        }

        <span className="tooltiptext">viewer</span>
      </div>
      <div
        className="btn tooltip"
        style={{
          pointerEvents: isAnimation && "none",
        }}
        onClick={() => {
          state.showPresentation = true;
        }}
      >
        {!snap.showPresentation ? (
          <MdAirplay
            className="btn-icon"
            style={{
              color: isAnimation && "#494949",
            }}
          />
        ) : (
          <MdAirplay
            className="btn-icon"
            style={{
              color: isAnimation && "#494949",
            }}
          />
        )}
        <span className="tooltiptext">Show Video</span>
      </div>
      <div
        className="btn tooltip"
        style={{ backgroundColor: "white" }}
        onClick={() => (state.color = "#E4E2E2")}
      >
        <span className="tooltiptext">white</span>
      </div>
      <div
        className="btn tooltip"
        style={{ backgroundColor: "#26249C" }}
        onClick={() => (state.color = "#2F1C55")}
      >
        <span className="tooltiptext">blue</span>
      </div>
      <div
        className="btn tooltip"
        style={{ backgroundColor: "black" }}
        onClick={() => (state.color = "#222020")}
      >
        <span className="tooltiptext">black</span>
      </div>
    </div>
  );
};
