import { proxy } from "valtio";

const state = proxy({
  intro: false,
  colors: ["#ccc", "#EFBD4E", "#80C670", "#726DE8", "#EF674E", "#353934"],
  decals: ["react", "three2", "pmndrs"],
  color: "#000000",
  decal: "three2",
  displayInfoButton: false,
  showVideo: true,
  showPresentation: false,
  displayInformation: { state: false, id: "wheel" },
});

export { state };
