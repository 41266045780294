import { useLoader } from "@react-three/fiber";
import {
  EffectComposer,
  Bloom,
  LUT,
  SMAA,
  BrightnessContrast,
} from "@react-three/postprocessing";
import { LUTCubeLoader } from "postprocessing";

export function Effects() {
  const texture = useLoader(LUTCubeLoader, "/textures/F-6800-STD.cube");
  return (
    <EffectComposer disableNormalPass>
      <SMAA />
      <Bloom mipmapBlur luminanceSmoothing={0} intensity={0.75} />
      <LUT lut={texture} />
      <BrightnessContrast
        brightness={-0.1} // brightness. min: -1, max: 1
        contrast={0.2} // contrast: min -1, max: 1
      />
    </EffectComposer>
  );
}
