import { useMemo, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { applyProps } from "@react-three/fiber";
import { LoopOnce, DoubleSide } from "three";

export function Brabus(props) {
  const { scene, materials, animations } = useGLTF("/model/car-model.glb");

  const { ref, actions, names } = useAnimations(animations);
  useMemo(() => {
    applyProps(materials.black_main_metal, {
      metalness: 0.5,
      roughness: 0.5,
      clearcoat: 1,
      clearcoatRoughness: 0.1,
      color: props.color,
    });
    applyProps(materials.black_body_plastic, {
      metalness: 1,
      roughness: 0.2,
      opacity: 0.3,
      color: props.color,
    });
    applyProps(materials.gray_main_metal, {
      metalness: 0,
      roughness: 0,
      color: "#C5C5C5",
    });
    applyProps(materials.gray_behind_metal, {
      metalness: 0,
      roughness: 0,
      color: "#999999",
    });
    applyProps(materials.gray_interior_detais, {
      metalness: 0,
      roughness: 1,
      color: "#C99B6E",
    });
    applyProps(materials.gray_interior_datail_2, {
      metalness: 0,
      roughness: 1,
      color: "#C99B6E",
    });
    applyProps(materials.interior_soft_gray_datails, {
      metalness: 0,
      roughness: 1,
      color: "#C99B6E",
    });
    applyProps(materials.interior_black_details, {
      metalness: 0,
      roughness: 1,
      color: "#383838",
    });
    applyProps(materials.gray_main, {
      metalness: 0,
      roughness: 1,
      color: "#C99B6E",
    });
    applyProps(materials.safety_belts, {
      metalness: 0,
      roughness: 1,
      color: "#2C2620",
    });
    applyProps(materials.black_pillow, {
      metalness: 0,
      roughness: 1,
      color: "#C99B6E",
    });
    applyProps(materials.brake, {
      metalness: 0,
      roughness: 1,
      color: "#BB5D00",
    });
    applyProps(materials.black_details, {
      metalness: 0.5,
      roughness: 0,
      color: "#A3A3A3",
    });
    applyProps(materials.backlights_background, { color: "#FF0000" });
    applyProps(materials.lights_red, {
      metalness: 1,
      roughness: 0,
      color: "#FF0000",
    });
    applyProps(materials.main_glass, {
      metalness: 0.8,
      roughness: 0.3,
      opacity: 0.9,
      color: "#000000",
      side: DoubleSide,
    });
    applyProps(materials.sunroof, {
      metalness: 1,
      roughness: 0,
      opacity: 0.3,
      color: "#4E4E4E",
    });
    applyProps(materials.number_plate, {
      metalness: 0,
      roughness: 0,
      toonMap: false,
    });
    applyProps(materials.logo, {
      metalness: 0,
      roughness: 0,
      toonMap: false,
    });
    applyProps(materials.car_disks, {
      metalness: 1,
      roughness: 0,
      color: "#494646",
    });
    applyProps(materials.tires, {
      metalness: 0.2,
      roughness: 0.3,
      color: "#141414",
    });
    applyProps(materials.red_datails, { color: "#FF0000" });
  }, [props.color, materials]);
  const playAnim = (index) => {
    actions[names[index]].setLoop(LoopOnce);
    actions[names[index]].timeScale = 1;
    actions[names[index]].reset().fadeIn(0.5).play().clampWhenFinished = true;
  };
  const playAnimClose = (index) => {
    actions[names[index]].setLoop(LoopOnce);
    actions[names[index]].time = actions[names[index]].duration / 2;
    actions[names[index]].timeScale = 1;
    actions[names[index]].fadeIn(0.5).play().clampWhenFinished = true;
  };
  const playAnimOut = (index) => {
    actions[names[index]].fadeOut(0.5);
  };
  const openingDoor = () => {
    // playAnim(1);
    playAnim(3);
    playAnim(5);
    playAnim(7);
    playAnim(9);
  };
  const closeDoor = () => {
    // playAnim(0);
    playAnimClose(2);
    playAnimClose(4);
    playAnimClose(6);
    playAnimClose(8);
  };
  const openingDoorOut = () => {
    // playAnimOut(1);
    playAnimOut(3);
    playAnimOut(5);
    playAnimOut(7);
    playAnimOut(9);
  };
  const closeDoorOut = () => {
    // playAnimOut(0);
    playAnimOut(2);
    playAnimOut(4);
    playAnimOut(6);
    playAnimOut(8);
  };
  useEffect(() => {
    if (props.open) {
      openingDoor();
    } else {
      closeDoor();
    }
    return () => {
      if (props.open) {
        openingDoorOut();
      } else {
        closeDoorOut();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);
  useEffect(() => {
    playAnim(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <group ref={ref}>
      <primitive object={scene} scale={1} position={[0, -1.16, 0]} />
    </group>
  );
}
