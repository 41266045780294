export const car_details = {
  wheel: {
    position: [4, 2, 2.3],
    lines: [
      [
        [0.9, -0.7, 1.3],
        [2.5, 2, 2.3],
      ],
      [
        [2.5, 2, 2.3],
        [3.5, 2, 2.3],
      ],
    ],
    buttonName: "WHEELS & CHASSIS",
    title: "WHEELS & CHASSIS. DESIGN MEETS HIGHTECH.",
    content:
      "I have a luxury brand and am looking to have my personal website designed within the Systeme.io platform. I Looking to work with a designer that knows this platform well and create a stunning design.",
  },
  engine: {
    position: [-3, 1, -3],
    lines: [
      [
        [-0.2, -0.3, -2.3],
        [-1.5, 1, -3],
      ],
      [
        [-1.5, 1, -3],
        [-2.5, 1, -3],
      ],
    ],
    buttonName: "POWER & SOUND",
    title: "POWER & SOUND. OUTSTANDING PERFORMANCE.",
    content:
      "The tuned engine produces significantly more torque: An added 150 Nm (111 lb-ft) mean a proud 1,000 Nm (737 lb-ft) at a low 3,600 rpm. The driving performance is accordingly outstanding for an off-roader: The BRABUS supercar sprints from rest to 100 km/h (62 mph) in just 4.1 seconds. The top speed is electronically limited to 240 km (149 mph) due to the high vehicle weight. The nine-speed automatic transmission can optionally be shifted manually with the ergonomic BRABUS RACE aluminum paddle shifters on the steering wheel.",
  },
  interior: {
    position: [-3, 2, 0],
    lines: [
      [
        [-0.5, 0, 0],
        [-2, 2, 0],
      ],
      [
        [-2, 2, 0],
        [-2.5, 2, 0],
      ],
    ],
    buttonName: "INTERIOR",
    title: "INTERIOR. INDIVIDUAL HANDMADE MASTERPIECES.",
    content:
      "BRABUS presents style for individualists. The extravagant BRABUS fine leather styles impress with exclusivity, endless colour and material combinations and outstanding quality. Nothing is impossible: From the floor and trunk in quilted leather to the alcantara starry sky – the combinations are endless. Be inspired by worldwide influences and discover our Santorini Style, the Capetown Style and the Upper-East Side Style.",
  },
  exterior: {
    position: [3, 1.5, -1.3],
    lines: [
      [
        [0, 0, -1.3],
        [2, 1.5, -1.3],
      ],
      [
        [2, 1.5, -1.3],
        [2.5, 1.5, -1.3],
      ],
    ],
    buttonName: "EXTERIOR",
    title: "EXTERIOR. APPEARANCE WITH CHARISMA.",
    content:
      "The spectacular appearance of the cross-country vehicle matches its exceptional performance: The combination of BRABUS WIDESTAR widebody version and mighty 23-inch forged wheels sets the five-door vehicle even further apart from the crowd. The high-performance tires  are supplied by BRABUS technology partners CONTINENTAL or YOKOHAMA.",
  },
};
