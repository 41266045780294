import { Html, Line } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { state } from "../../store";

export const DetailButton = ({
  position = [0, 0, 0],
  lines,
  buttonName = "buttonName",
  id,
}) => {
  const snap = useSnapshot(state);

  const handleClick = (id) => {
    state.displayInformation.state = true;
    state.displayInformation.id = id;
  };

  return (
    <group visible={snap.displayInfoButton}>
      {lines.map((line, index) => (
        <Line key={index} points={line} lineWidth={1} color="#FFFFFF" />
      ))}

      {snap.displayInfoButton && (
        <Html
          style={{ userSelect: "none" }}
          position={position}
          transform
          sprite
        >
          <div className="information-btn" onClick={() => handleClick(id)}>
            <p className="information-btn-text">{buttonName}</p>
          </div>
        </Html>
      )}
    </group>
  );
};
