import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useProgress } from "@react-three/drei";

export default function Loading() {
  const { progress } = useProgress();
  const bg = useRef(null);
  const [hide, setHide] = useState(false);
  const [finishIn, setFinishIn] = useState(false);

  const fadeIn = () => {
    gsap.to(bg.current, {
      duration: 2,
      ease: "power1.out",
      opacity: 1,
      onComplete: () => {
        setFinishIn(true);
      },
    });
  };

  const fadeOut = () => {
    gsap.to(bg.current, {
      duration: 2,
      ease: "power1.out",
      opacity: 0,
      onComplete: () => {
        setHide(true);
      },
    });
  };

  useEffect(() => {
    if (!finishIn) fadeIn();
    if (progress === 100 && finishIn) fadeOut();
  }, [progress, finishIn]);

  return hide ? null : (
    <div ref={bg} className="loading-page">
      <div className="loading">
        <img
          alt="3D brabus configurator"
          src="/brabus-vector-logo/brabus_white.svg"
          className="loading-logo"
        />
        <div
          className="loading-bar"
          style={{ width: `${(250 * progress) / 100}px` }}
        />
        <p>LOADING...</p>
      </div>
      {/* <p className="mail">valcano103@gmail.com</p> */}
    </div>
  );
}
